.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  background-color: #262626;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.root {
  flex-grow: 1;
  min-width: 800px;
  max-width: 1200px;
  padding-bottom: 2em;
}

@media (min-width: 300px) {
  .root {
    min-width: 300px;
    margin: auto;
  }
}

.MuiAppBar-colorPrimary {
  background-color: #161616 !important;
}

.MuiPaper-root {
  background-color: #161616 !important;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 60px;
  display: inline-block;
  margin-left: -25px;
  margin-top: 3px;
}

#reports-menu > div.MuiMenu-paper {
  padding-top: 42px;
}

.username {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.siteTitle {
  flex-grow: 1;
  color: white;
  font-family: Arial, Helvetica, sans-serif !important;
  text-decoration: none !important;
}

.menubar {
  margin-top: 70px;
}

.menubar-buttons {
  margin-left: auto;
  margin-right: auto;
}

.menu-button {
  margin-right: 0.5rem;
}

.MuiDrawer-paperAnchorTop {
  padding: 5em 20% 1em 20%;
}

.drawer {
  overflow: 'auto';
}

.lowerCards {
  padding-bottom: 40px !important;
}

.topCards {
  padding-bottom: 40px !important;
}

.MuiAppBar-root {
  z-index: 1500 !important;
}
