.dashboard {
  height: 90vh;
  width: 100vw;
  margin-top: 10px;
  flex-grow: 1;
}

.circular-progress {
  margin-top: 70px;
  padding: 300px;
  text-align: center;
}