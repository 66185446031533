#story {
    margin-top: 5em;
}

#story p {
    margin-bottom: 1em;
}

#story svg {
    margin: 2em 0em 2em 0em;
}

.storyTitle {
    padding: .6em 1.2em .6em 1.2em;
}

.categoryText {
    margin: 1.2em .6em .6em .6em;
    padding: .4em;
    border: 1px solid;
    border-radius: 3px;
}

.description {
    padding: 0em 4em 2em 4em;
}

.dataTable {
    padding: 2.4em 2em 2.4em 2em;
}

.dataTable > thead > tr > th {
    font-weight: bold;
}

.MuiTableContainer-root {
    width: inherit !important;
}

.subText {
    padding: 1em 3em 0em 3em;
}

.storySection {
     min-width: 1050px;
     margin-top: 2em;
     padding: 2.5em 0em 2em 0em;
     margin-bottom: 2em;
}


