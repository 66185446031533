.oneIcon {
    height: 10em;
    width: 10em;
    background-position: center;
}

.lowerIcon .oneIcon {
    height: 9em;
    width: 8em;
    background-position: center;
}

.twoIcon {
    height: 5em;
    width: 5em;
    background-position: center;
}

.twoTop {
    padding: 2em 0 2em 0;
}

.threeIcon {
    height: 5em;
    width: 5em;
    background-position: center;
}

.threeTop {
    margin: auto;
    position: relative;
    max-width: 10em;
}