.homeDesc {
    padding: 0 2em 4em 2em;
    text-align: center;
}

.homeTop {
    padding: 5em 0em 2em 0em;
    margin: auto;
    text-align: center;
}

.homeTitle {
    max-width: 300;
    min-width: 14em;
    margin: 2em auto 1em auto;
}

.paper {
    height: 100%;
    text-align: center;
    padding: 1em;

}

.paper:hover {
    color: #4f65dd;
    box-shadow:3px 5px 5px #060606;
}

.revisionDate {
    color: #696969;
    margin-bottom: .5em;
}

.upperIcon {
    min-width: 14em;
    height: 10em;
}

.upperIcon .twoIcon {
    height: 7em !important;
    width: 7em !important;
}

.topCards {
    max-width: 33%;
    min-width:300px;
}

.lowerIcon {
    height: 10em;
}

.paperLower {
    text-align: center;
    padding: 1em;
    height: 100%;
}

.paperLower:hover {
    color: #3f51b5;
}

.lowerCards {
    min-width: 200px;
}

.cardTitle {
    margin-bottom: .8em;
}

.topDivider {
    padding-bottom: 1.2em;
}

.lowerSection{
    @media (min-width : 400px) {
        padding: 0 5em 0 5em;
    }
}

.reportDesc {
    padding: 2em;
}

.reportDesc button {
    margin: 0 .5em 0 .5em;
}

.workbookLinks {
    color: white;
    font-size: .8em;
}

.workbookLinks:hover {
    text-decoration: none !important;
    color: #4f65dd !important;
}

.workbookGridContainer {
    margin-top:2em;
    margin-bottom: 2em;
}

.workbookGrid {
    max-height: 20em;
}

@media only screen and (max-width: 1200px){
    .workbookGrid {
        max-height: unset;
        max-width: unset;
    }
    .workbookGrid .MuiGrid-grid-xs-3 {
        max-width: 100%;
    }
}

.requestInput {
    width: 100%;
    padding-top: 2em;
}